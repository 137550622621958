import { styled } from '@mui/material';
import React from 'react';

export const Container = styled('div')`
  height: calc(100vh);
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
