import React, { ReactElement } from 'react';
import * as S from './styles';
import { Container } from '@mui/material';
import Layout from '@components/Layout/Layout';
import {useRecoilState, useRecoilValue} from 'recoil';
import userAtom from '@recoil/user';
import {useRouter} from "next/router";

const HomePage = () => {
  const [user, setUser] = useRecoilState(userAtom);
  const router = useRouter();
  const {isInitialized, isAuthenticated} = useRecoilValue(userAtom);

  if (isAuthenticated) {
    router.push('/main');
  }

  return (
    <S.Container>
      <Container style={{ position: 'absolute', padding: 0 }}>테스트</Container>
    </S.Container>
  );
};

export default HomePage;

HomePage.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};
