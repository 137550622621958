import { ReactElement, ReactNode } from 'react';
import { BOTTOM_BAR_MOBILE_HEIGHT, CHAT_BOTTOM_BAR } from '@configs';

type Props = {
  children: ReactNode;
  header?: ReactElement;
  footer?: ReactElement;
  bottomNav?: ReactElement;
  height?: boolean;
  scroll?: boolean;
};

export default function Layout({ children, header, footer, bottomNav, height, scroll }: Props) {
  return (
    <>
      {header && header}
      {/*{children}*/}
      <main>{children}</main>
      {footer && footer}
      {bottomNav && <div css={{ height: height ? CHAT_BOTTOM_BAR : BOTTOM_BAR_MOBILE_HEIGHT }} />}
      {bottomNav && bottomNav}
    </>
  );
}

// NOTE
// footer가 있으면 main 밑에 footer달고
//  bottomNav 까지 있으면 bottomNav는 bottom 0px 에서 fixed + bottomNav의 높이만큼 빈 block div를 MartCart 밑에 추가

// TODO
// 아이폰의 경우 safe area inset bottom 까지 생각해야됨
